import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import Title from "../../ui/Title";
import { ArrowBack, CloudDownload, CloudUpload } from "@material-ui/icons";
import { common } from "../../messages";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import utils, { sanitizeForFileName } from "../../utils/utils";

export default function ManageCampusCertificates() {
  const {
    bmapi,
    notifyError,
    startLoading,
    stopLoading,
    notifySuccess,
  } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const [matches, setMatches] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  const fileInputRef = useRef(null); // Reference for the hidden file input

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const loadMatches = useCallback(() => {
    startLoading();
    bmapi
      .getCampusCertificatesResume()
      .then((resp) => {
        setMatches(resp || []);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(stopLoading);
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  useEffect(() => {
    loadMatches();
  }, [loadMatches]);

  const download = (event) => {
    startLoading();
    bmapi
      .getCampusCertificatesCsv({ campaign_id: event.campaign_id })
      .then((blob) => {
        const now = new Date();
        const timestamp = `${now.getFullYear()}${(now.getMonth() + 1)
          .toString()
          .padStart(2, "0")}${now
          .getDate()
          .toString()
          .padStart(2, "0")}_${now
          .getHours()
          .toString()
          .padStart(2, "0")}${now
          .getMinutes()
          .toString()
          .padStart(2, "0")}${now.getSeconds().toString().padStart(2, "0")}`;

        const sanitizedCampaignName = event.campaign_name.replace(/ /g, "_");
        const filename = sanitizeForFileName(
          `${sanitizedCampaignName}_${timestamp}.zip`
        );

        utils.download(blob, filename);
      })
      .catch((e) => {
        notifyError("" + e);
      })
      .finally(stopLoading);
  };

  const upload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    startLoading();

    const campaignId = fileInputRef.current.dataset.campaignId;
    const campaignName = fileInputRef.current.dataset.campaignName;

    const formData = new FormData();
    formData.append("file", file);

    bmapi
      .uploadCampusCertificates({ campaign: campaignId }, formData)
      .then((resp) => {
        console.log("----------ghjk---------", resp);
        setDialogData({ campaignName, resp: resp }); // Set campaign name for dialog
        setDialogOpen(true); // Open dialog
      })
      .catch((err) => {
        notifyError(getErrorMessageString(err, intl));
      })
      .finally(stopLoading);

    // Clear the file input value after upload to allow re-uploads
    e.target.value = null;
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleProceed = (uploadId) => {
    startLoading();
    bmapi
      .processUpload({ upload: uploadId })
      .then((resp) => {
        console.log("------", resp);
        notifySuccess(
          `File per "${dialogData.campaignName}" caricato correttamente`
        );
      })
      .catch((err) => {
        notifyError(getErrorMessageString(err, intl));
      })
      .finally(stopLoading);
    setDialogOpen(false);
  };

  const wf = dialogData?.resp?.warn_users?.filter((u) => u.code === "wf");
  const nu = dialogData?.resp?.warn_users?.filter((u) => u.code === "nu");
  const nc = dialogData?.resp?.warn_users?.filter((u) => u.code === "nc");
  const dp = dialogData?.resp?.warn_users?.filter((u) => u.code === "dp");
  const na = dialogData?.resp?.warn_users?.filter((u) => u.code === "na");

  return (
    <Container maxWidth="sm">
      <Title>Gestione attestati</Title>

      <Box>
        <Box mt={2} style={{ clear: "right" }}>
          {matches?.map((event) => (
            <Card key={event.campaign_id}>
              <CardContent>
                <Typography variant="h6">{event.campaign_name}</Typography>

                <Typography gutterBottom>
                  Nominativi da attestare: {event.count}
                </Typography>

                <Box my={0} display="flex" justifyContent="space-between">
                  <Button
                    onClick={() => download(event)}
                    startIcon={<CloudDownload />}
                  >
                    Scarica (da attestare)
                  </Button>
                  <Button
                    onClick={() => {
                      fileInputRef.current.dataset.campaignId =
                        event.campaign_id;
                      fileInputRef.current.dataset.campaignName =
                        event.campaign_name;
                      fileInputRef.current.click();
                    }}
                    startIcon={<CloudUpload />}
                  >
                    Carica (attestati)
                  </Button>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={upload}
      />

      <Box my={2}>
        <Button onClick={goToHome} startIcon={<ArrowBack />}>
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>

      {/* Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Caricamento certificati</DialogTitle>
        <DialogContent>
          <Typography>Certificati per: {dialogData?.campaignName}</Typography>
          <Box display="flex" flexDirection="column" width="100%" mt={2}>
            {dialogData?.resp?.warn_events?.length > 0 && (
              <Typography variant="h6">Il nome evento non coincide</Typography>
            )}
            {dialogData?.resp?.warn_events?.map((we, i) => (
              <Box key={"we" + i} mb={1}>
                {we.event_name}
              </Box>
            ))}

            {wf?.length > 0 && (
              <Typography variant="h6">
                Righe non corrette (da ignorare)
              </Typography>
            )}
            {wf?.map((we, i) => (
              <Box key={"wf" + i} mb={1}>
                Riga: {we.row}
              </Box>
            ))}

            {nu?.length > 0 && (
              <Typography variant="h6">
                Utenti non trovati (da ignorare)
              </Typography>
            )}
            {nu?.map((we, i) => (
              <Box key={"we" + i} mb={1}>
                {we.email}
              </Box>
            ))}

            {dp?.length > 0 && (
              <Typography variant="h6">Duplicati (da ignorare)</Typography>
            )}
            {dp?.map((we, i) => (
              <Box key={"we" + i} mb={1}>
                {we.email}
              </Box>
            ))}

            {na?.length > 0 && (
              <Typography variant="h6">
                Certificato non previsto ??? (da ignorare)
              </Typography>
            )}
            {na?.map((we, i) => (
              <Box key={"we" + i} mb={1}>
                {we.email}
              </Box>
            ))}

            {nc?.length > 0 && (
              <Typography variant="h6">
                Nuovi certificati (da inserire)
              </Typography>
            )}
            {nc?.map((we, i) => (
              <Box key={"we" + i} mb={1}>
                {we.email}
              </Box>
            ))}

            <Typography variant="h6">
              Certificati da inserire: {dialogData?.resp?.count}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Chiudi
          </Button>
          {dialogData?.resp?.count > 0 && (
            <Button
              onClick={() => handleProceed(dialogData?.resp?.upload_id)}
              color="primary"
            >
              Procedi
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
}
